import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const StudiophotoPage = () => (
  <Layout>
    <SEO title="スタジオ写真" />
    <div
      className="flex h-[20vh] bg-cover bg-center bg-opacity-25 justify-center content-center"
      style={{ backgroundImage: `url('../studiophoto-header.webp')`, backgroundSize: 'cover' }}
    >
      <p className="text-white text-center text-3xl lg:text-4xl self-center">スタジオ写真</p>
    </div>
    
    <div className="container space-y-5 px-5 lg:px-0 my-8 h-30 justify-center content-center">
      <div className="flex flex-row">
        <button class="bg-gray-200 hover:bg-gray-300 text-textblack py-2 px-10 rounded" onClick={()=>{navigate("/studiophoto/price")}}>
          <p className="text-textblack">価格</p>
        </button>
      </div>
      <div className="space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">お宮参り記念</p>
        <p className="text-textblack">
         初めて撮る家族写真。我が子の成長の記録を写真として残し、成長したときの想い出に...。おじいちゃん、おばあちゃんと親子三世代ご一緒にどうぞ。
        </p>
        <div className="flex flex-row gap-2">
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-1-1.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-1-2.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-1-3.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-1-4.webp"
          />
        </div>
      </div>
      <div className="space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">初節句記念</p>
        <p className="text-textblack">
          新しい家族の大切な思い出に。ご家族一緒にどうぞ。
        </p>
        <div className="flex flex-row gap-2">
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-2-1.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-2-2.webp"
          />
          <StaticImage
            className="flex-initial basis-1/3"
            src="../../images/studiophoto/studio-2-3.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-2-4.webp"
          />
        </div>
      </div>
      <div className="space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">七五三記念</p>
        <p className="text-textblack">
          七五三、節目の大切な思い出。ご家族一緒にどうぞ。
        </p>
        <div className="flex flex-row gap-2">
          <StaticImage
            className="flex-initial basis-1/3"
            src="../../images/studiophoto/studio-3-1.webp"
          />
          <StaticImage
            className="flex-initial basis-1/3"
            src="../../images/studiophoto/studio-3-2.webp"
          />
          <StaticImage
            className="flex-initial basis-1/3"
            src="../../images/studiophoto/studio-3-3.webp"
          />
        </div>
      </div>
      <div className="space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">入園入学・卒園卒業記念</p>
        <div className="flex flex-row gap-2 container">
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-4-1.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-4-2.webp"
          />
          <StaticImage
            className="flex-initial basis-1/3"
            src="../../images/studiophoto/studio-4-3.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-4-4.webp"
          />
        </div>
      </div>
      <div className="space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">成人記念</p>
        <div className="flex flex-row gap-2">
          <StaticImage
            className="flex-initial basis-1/3"
            src="../../images/studiophoto/studio-5-1.webp"
          />

          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-5-3.webp"
          />
          
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-5-8.webp"
          />
        </div>
        <div className="flex flex-row gap-2">
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-5-5.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-5-4.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-5-7.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-5-6.webp"
          />
        </div>
      </div>
      <div className="space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">家族写真</p>
        <div className="flex flex-row gap-2">
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-6-1.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-6-2.webp"
          />
          <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-6-3.webp"
          />
                    <StaticImage
            className="flex-initial basis-1/4"
            src="../../images/studiophoto/studio-5-2.webp"
          />
        </div>
      </div>
      <div className="space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">生前遺影・敬老記念</p>
      </div>
    </div>
  </Layout>
)

export default StudiophotoPage